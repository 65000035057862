import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import UnAuthNavDesktop from "../../components/Navbar/UnAuthNavDesktop";
import styles from "./BlogCategory.module.css";
import Image3 from "../../assets/Blog/cardIconMini.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

const BlogCategory = () => {
  const { catId } = useParams();
  const [blogsData, setBlogsData] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [currentCategory, setCurrentCategory] = useState(null);
  // console.log(catId);

  const fetchAllCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/categories/all`
      );

      if ((response.status = 200)) {
        // console.log(response.data.data);
        setCategories(response.data.data);
      }
    } catch (error) {
      console.error(error.response.data.error);

      console.log(error.response.data.message);
    }
  };

  const getAllBlogsData = async () => {
    try {
      let response;
      if (catId !== "0") {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blogs/${catId}`
        );
      } else if(catId==="0") {
        console.log(catId);
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blogs/all`
        );
        
      }
      if ((response.status = 200)) {
        console.log(response.data.data);
        setBlogsData(response.data.data);
      }
    } catch (error) {
      console.error(error.response.data.error);

      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    getAllBlogsData();
    fetchAllCategories();
  }, [catId]);

  useEffect(() => {
    if (categories) {
      const temp = categories.find((val) => val.category_id == catId);
      console.log(catId, categories);

      setCurrentCategory(temp);
    }
  }, [categories, catId]);

  //   const handleLinkClick = (id) => {
  //     navigate(`/blog/category/${id}`);
  // };

  console.log(blogsData);
  console.log(currentCategory);

  return (
    <>
      <UnAuthNavDesktop />
      <div className={styles.mainContainer}>
        <div className={styles.sideNavContainer}>
          <ul className={styles.sideNav}>
            <li className={styles.sideNavContent}>
              <Link to="/blogs/category/0"> All Blogs</Link>
            </li>
            {categories.map((val) => (
              <li className={styles.sideNavContent} key={val.category_id}>
                <Link to={`/blogs/category/${val.category_id}`}>
                  {" "}
                  {val.category_name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.pageWrapper}>
          <div className={styles.textDiv}>
            <h5>
              {currentCategory ? currentCategory.category_name : "All Blogs"}
            </h5>
          </div>
          <div>
            {blogsData.map((val) => {
              return (
                <Link to={`/blogs/details/${val.blog_id}`}>
                <Card
                  key={val.blog_id}
                  sx={{
                    width: "73.077vw",
                    height: "14.615vw",
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#2e2d2d25",
                    marginBottom:"1vw",
                    border: "2px solid #333333",
                  }}
                >
                  <div>
                    <CardMedia
                      // sx={{ height: 140 }}
                      sx={{
                        width: "20.769vw",
                        height: "100%",
                      }}
                      image={val.image_url}
                      title={val.blog_title}
                    />
                  </div>
                  <div className={styles.cardContent}>
                    <CardContent sx={{ paddingTop: "1.795vw" }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{
                          color: "#fff",
                          fontSize: "1.282vw",
                        }}
                      >
                        {val.blog_title}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          color: "#fff",
                          fontSize: "1.026vw",
                          maxHeight:"50%",
                          overflow:"hidden"
                        }}
                        className={styles.shortDscriptionContainer}
                      >
                        <div style={{
                          maxHeight:"50%"
                        }}
                          dangerouslySetInnerHTML={{
                            __html: val.short_description,
                          }}
                        ></div>
                      </Typography>
                    </CardContent>
                    <CardActions sx={{display:"flex",alignItems:"baseline"}} >
                      <div className={styles.lowerContent}>
                        <Link
                          style={{
                            color: "#44CCFF",
                            cursor: "pointer",
                          }}
                          className={styles.readMore}
                          to={`/blogs/details/${val.blog_id}`}
                          // onClick={handleLinkClick}
                        >
                          Read More &#8594;
                          <hr className={styles.readMeLine} />
                        </Link>
                        <div className={styles.lowerRightCardContainer}>
                          <p className={styles.dateSpan}>Oct 23, 2023 </p>
                          <img
                            src={Image3}
                            style={{ height: "1.154vw" }}
                            alt="Icon"
                          />
                          <p> by {val.author}</p>
                        </div>
                      </div>
                    </CardActions>
                  </div>
                </Card>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCategory;
