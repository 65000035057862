import React, { useEffect, useState } from "react";
import isDesktop from "../../isDesktop";
import UnauthNav from "../../components/Navbar/UnauthNav";
import styles from "./BlogsMobile.module.css";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Image1 from "../../assets/Blog/SubHeaderImg1.png";
import Image2 from "../../assets/Blog/SubHeaderImg2.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import Image3 from "../../assets/Blog/cardIconMini.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const DesktopComponent = React.lazy(() => import("./Blogs"));

const BlogsMobile = () => {
  const isDesktopDevice = isDesktop();
  const [categories, setCategories] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [blogs, setBlogs] = useState([]);
  const [title,setTitle]=useState("")

  const fetchAllCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/categories/all`
      );

      if ((response.status = 200)) {
        // console.log(response.data.data);
        setCategories(response.data.data);
      }
    } catch (error) {
      console.error(error.response.data.error);

      console.log(error.response.data.message);
    }
  };

  const fetchBlogs = async () => {
    try {
      let uri;
      if (tabValue != 0) {
        uri = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blogs/${tabValue}`;
      } else {
        uri = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blogs/all`;
      }

      const response = await axios.get(uri);
      console.log(response.data.data);

      setBlogs(response.data.data);
    } catch (error) {
      console.error(error);
      console.log("Error fetching blogs");
    }
  };

  const onTabChange = (e, value) => {
    // console.log();

    setTabValue(value);
  };

  useEffect(() => {
    if (tabValue !== null && tabValue !== undefined) {
      fetchBlogs();
      const name=categories?.find(val=>val.category_id===tabValue)?.category_name;
      setTitle(name)

    }
  }, [tabValue]);

  useEffect(() => {
    fetchAllCategories();
  }, []);

  return isDesktopDevice ? (
    <DesktopComponent />
  ) : (
    <div>
      <UnauthNav />
      <div className={styles.mainContainer}>
        <div className={styles.subHeader}>
          <p>
            Math is not a Subject,
            <br />
            Math is a <span>SKILL</span>
          </p>
        </div>
        <div className={styles.carouselContainer}>
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            className={styles.carousel}
          >
            <div className={styles.HeaderImageContainer}>
              <img src={Image1} alt="" />
            </div>
            <div className={styles.HeaderImageContainer}>
              <img src={Image2} alt="" />
            </div>
          </Carousel>
        </div>
        <div className={styles.TabContainer}>
          <Tabs value={tabValue} onChange={onTabChange} variant="scrollable">
            <Tab
              sx={{
                color: "#cccccc",
                "&.Mui-selected": {
                  color: "white",
                  borderBottom: "1px solid #44ccff",
                },
              }}
              label={"All Blogs"}
              key={0}
              value={0}
            />
            {categories.map((category) => (
              <Tab
                sx={{
                  color: "#cccccc",
                  "&.Mui-selected": {
                    color: "#cccccc",
                    borderBottom: "1px solid #44ccff",
                  },
                }}
                key={category?.category_id}
                value={category?.category_id}
                label={category?.category_name}
              />
            ))}
          </Tabs>
        </div>
        <div className={styles.blogsContainer}>
          {tabValue === 0 && (
            <div>
              <h4>Trending Articles</h4>
              <div className={styles.swiperContainer}>
              <Swiper
                spaceBetween={13}
                slidesPerView={2}
                onSlideChange={() => console.log("slide change")}
                className={styles.cardContainer}
              >
                {blogs.map((val) => (
                  <SwiperSlide>
                    <Link to={`/blogs/details/${val.blog_id}`}>
                    <Card
                      key={val.blog_id}
                      sx={{
                        width: "100%",
                        height: "16.5rem",
                        backgroundColor: "#2E2D2D40",
                      }}
                    >
                      <CardMedia
                        sx={{ height: "10.938rem" }}
                        image={val.image_url}
                        title={val.blog_title}
                      />
                      <CardContent
                        sx={{
                          height: "1rem",
                          paddingBottom: "1vw",
                          paddingTop:"0.5rem",
                          paddingLeft:"0.5rem"
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          sx={{
                            color: "#ffffff",
                            fontSize: "0.875rem",
                          }}
                        >
                          {val.blog_title}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{
                          position: "relative",
                          // bottom: "0px",
                          top:"2rem",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        className={styles.cardActions}
                      >
                        <Link
                          style={{
                            color: "#44CCFF",
                            cursor: "pointer",
                            width:"3.313rem"
                          }}
                          to={`/blogs/details/${val.blog_id}`}
                          className={styles.readMore}
                        >
                          Read More &#8594;
                          <hr className={styles.readMeLine} />
                        </Link>
                        <div className={styles.lowerRightCardContainer}>
                          <p className={styles.dateSpan}>Oct 23, 2023 </p>
                          <img
                            src={Image3}
                            style={{ height: "1.154vw" }}
                            alt="Icon"
                          />
                          <p> by {val.author}</p>
                        </div>
                      </CardActions>
                    </Card>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
              </div>
            </div>
          )}
          {tabValue!=0 && <div>
              <h4>{title}</h4>
              <div className={styles.swiperContainer}>
              <Swiper
                spaceBetween={13}
                slidesPerView={2}
                onSlideChange={() => console.log("slide change")}
                className={styles.cardContainer}
              >
                {blogs.map((val,index) => (
                  <SwiperSlide key={index} >
                    <Link to={`/blogs/details/${val.blog_id}`}>
                    <Card
                      key={val.blog_id}
                      sx={{
                        width: "100%",
                        height: "16.5rem",
                        backgroundColor: "#2E2D2D40",
                      }}
                    >
                      <CardMedia
                        sx={{ height: "10.938rem" }}
                        image={val.image_url}
                        title={val.blog_title}
                      />
                      <CardContent
                        sx={{
                          height: "1rem",
                          paddingBottom: "1vw",
                          paddingTop:"0.5rem",
                          paddingLeft:"0.5rem"
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          sx={{
                            color: "#ffffff",
                            fontSize: "0.875rem",
                          }}
                        >
                          {val.blog_title}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{
                          position: "relative",
                          // bottom: "0px",
                          top:"2rem",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        className={styles.cardActions}
                      >
                        <Link
                          style={{
                            color: "#44CCFF",
                            cursor: "pointer",
                            width:"3.313rem"
                          }}
                          to={`/blogs/details/${val.blog_id}`}
                          className={styles.readMore}
                        >
                          Read More &#8594;
                          <hr className={styles.readMeLine} />
                        </Link>
                        <div className={styles.lowerRightCardContainer}>
                          <p className={styles.dateSpan}>Oct 23, 2023 </p>
                          <img
                            src={Image3}
                            style={{ height: "1.154vw" }}
                            alt="Icon"
                          />
                          <p> by {val.author}</p>
                        </div>
                      </CardActions>
                    </Card>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
              </div>
            </div>}
        </div>
      </div>
    </div>
  );
};

export default BlogsMobile;
