import React from 'react'
import DesktopNewSmartsaLogo from "../../assets/smartsa_new_logo.svg";
import styles from "./UAuthNavDesktop.module.css"
import { Link, NavLink } from 'react-router-dom';
import { Badge } from '@mui/material';

const UnAuthNavDesktop = () => {

  const navClass=({ isActive, isPending, isTransitioning }) =>
                  [
                    isPending ? styles.navPending : "",
                    isActive ?styles.navActive : "",
                    isTransitioning ? styles.navTransitioning : "",
                  ].join(" ")

  return (
    <div>
        <div style={{ background: "#000000"}} className={styles.topBar}>
        <div className={styles.logoIcon}>
          <Link to="/dashboard">
            <img src={DesktopNewSmartsaLogo} className={styles.iconStyle} alt="Smartsa Logo"/>
          </Link>
        </div>
        <div className={styles.topBarRight}>
            <ul className={styles.linkList}>
                <li>
                <NavLink className={navClass} to={"/#aboutus"}
                
                >About us</NavLink>
                </li>
                <li>
                <NavLink to={"/"}>Our Story</NavLink>
                </li>
                <li>
                <NavLink to={"/learn"}>Learn</NavLink>
                </li>
                <li>
                <NavLink to={"/lab-page"}>Labs</NavLink>
                </li>
                <li>
                <NavLink to={"/blogs"} className={navClass} >Blogs</NavLink>
                </li>
                <li>
                {/* <a href="/how-it-works">How it Works</a> */}
                <NavLink to={"/how-it-works"} className={navClass} >How it Works</NavLink>
                </li>
                <li>
                <NavLink to={"/help"}>Help</NavLink>
                </li>
                
            </ul>
        </div>
      </div>
    </div>
  )
}

export default UnAuthNavDesktop