import React, { useEffect, useState } from "react";
import UnAuthNavDesktop from "../../components/Navbar/UnAuthNavDesktop";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import styles from "./BlogDetails.module.css";
import Image from "../../assets/Blog/cardIconMini.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InstaIcon from "../../assets/icons/other_icons/instagram-icon.svg";
import LinkedinIcon from "../../assets/icons/other_icons/linkedin-icon.svg";
import FacebookIcon from "../../assets/icons/other_icons/facebook-icon.svg";
import ShareIcon from "@mui/icons-material/Share";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  ArrowDownwardSharp,
  KeyboardArrowDown,
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
} from "@mui/icons-material";
import { Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import isDesktop from "../../isDesktop";
import UnauthNav from "../../components/Navbar/UnauthNav";

const BlogsDetails = () => {
  const [blog, setBlog] = useState([]);
  const [subtitles, setSubtitles] = useState([]);
  const [subTitleDesc, setSubtitleDesc] = useState([]);
  const { blogId } = useParams();
  const [categoryId, setCategoryId] = useState(null);
  const [category, setCategory] = useState(null);
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const [open,setOpen]=useState(false)
  const isDesktopDevice = isDesktop();

  const fetchBlogDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blog/${blogId}`
      );

      if (response.status === 200) {
        setBlog(response.data.data);
        setCategoryId(response.data.data.category_id);
        if (response.data.data.subtitle_titles) {
          setSubtitles(JSON.parse(response.data.data.subtitle_titles));
        }
        if (response.data.data.subtitle_descriptions) {
          setSubtitleDesc(JSON.parse(response.data.data.subtitle_descriptions));
        }
      }
    } catch (error) {
      console.error(error.response.data.error);
      console.log(error.response.data.message);
    }
  };

  const fetchCategryName = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/category/${categoryId}`
      );

      if (response.status === 200) {
        setCategory(response.data.data);
        if (response.data.data.subtitle_titles) {
          setSubtitles(JSON.parse(response.data.data.subtitle_titles));
        }
        if (response.data.data.subtitle_descriptions) {
          setSubtitleDesc(JSON.parse(response.data.data.subtitle_descriptions));
        }
      }
    } catch (error) {
      console.error(error.response.data.error);
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchBlogDetails();
  }, [blogId]);

  useEffect(() => {
    if (categoryId) {
      fetchCategryName();
    }
  }, [categoryId]);

  // console.log(blog);

  const dateFormat = (d) => {
    const date = new Date(d);
    // console.log(date);

    const month = date.toLocaleDateString("default", { month: "short" });
    const day = date.getDay();
    const year = date.getFullYear();
    return `${month} ${day} ${year}`;
  };

  // dateFormat("2024-08-22T10:40:09.000Z")

  const onLikeClick = async () => {
    let likeIncrease = 1,
      dislikeIncrease = 0;

    if (dislike === true) {
      console.log(dislike);

      setDislike(false);
      dislikeIncrease = -1;
    }
    if (like) {
      setLike(false);
      likeIncrease = -1;
    } else {
      setLike(true);
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blog/${blogId}/updatelike`,
        {
          likeIncrease: likeIncrease,
          dislikeIncrease: dislikeIncrease,
        }
      );

      if (response.status === 200) {
        fetchBlogDetails();
        console.log("Likes updated Sucessfully");
      }
    } catch (error) {
      console.error(error.response.data.error);
      console.log(error.response.data.message);
    }
  };

  const onDislikeClick = async () => {
    let likeIncrease = 0,
      dislikeIncrease = 1;
    if (like) {
      setLike(false);
      likeIncrease = -1;
    }
    if (dislike) {
      setDislike(false);
      dislikeIncrease = -1;
    } else {
      setDislike(true);
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/manage-blogs/blog/${blogId}/updatelike`,
        {
          likeIncrease: likeIncrease,
          dislikeIncrease,
          dislikeIncrease,
        }
      );

      if (response.status === 200) {
        fetchBlogDetails();
        console.log("Dislike updated Sucessfully");
      }
    } catch (error) {
      console.error(error.response.data.error);
      console.log(error.response.data.message);
    }
  };

  const tocClick=()=>{
    setOpen(prev=>!prev)
  }

  return (
    <div className={styles.blogMainContainer}>
      {isDesktopDevice ? <UnAuthNavDesktop /> : <UnauthNav />}
      <div className={styles.blogContainer}>
        <div className={styles.sideNavContainer}>
          {isDesktopDevice && (
            <ul className={styles.sideNav}>
              <li className={styles.sideNavContent}>
                <a> Table of Content</a>
              </li>
              {blog.subtitle_titles &&
                JSON.parse(blog?.subtitle_titles).map((val, index) => (
                  <li className={styles.sideNavContent} key={index}>
                    <a href={`#${val}`}> {val}</a>
                  </li>
                ))}
            </ul>
          )}
        </div>
        <div className={styles.BlogPageContainer}>
          <div className={styles.backContainer}>
            <Link
              to={`/blogs${isDesktopDevice ? "/category/" : "?category="}${
                category?.category_id
              }`}
            >
              <KeyboardArrowLeftIcon /> {category?.category_name}
            </Link>
          </div>
          <div className={styles.blogContentContainer}>
            <h1>{blog?.blog_title}</h1>
            <div className={styles.authorNameContainer}>
              <div className={styles.authorName}>
                <img src={Image} alt="Logo" />
                <span> by {blog?.author}</span>
                <span style={{ fontSize: "0.625rem" }}>
                  {dateFormat(blog?.created_at)}
                </span>
              </div>
              <div className={styles.share}>
                {isDesktopDevice && "Share this Article"}
                <span>
                  <ShareIcon
                    sx={{ width: "1rem", height: "1rem", color: "white" }}
                  />
                </span>
              </div>
            </div>
            <div className={styles.imageContainer}>
              <img src={blog.image_url} alt="Image" />
            </div>
            {!isDesktopDevice && (
              <div className={styles.tableOfContentMobile}>
                <List className={styles.list}>
                  <ListItemButton onClick={tocClick} className={styles.listItem}>
                    <ListItemText style={{width:"fit-content"}} primary="Table of Content" />
                    <ListItemIcon style={{justifyContent:"end"}}>
                      <KeyboardArrowDown style={{color:"white"}} />
                    </ListItemIcon>
                  </ListItemButton>
                  <Collapse in={open} unmountOnExit >
                  <List className={styles.subList}>
                  {blog.subtitle_titles &&
                JSON.parse(blog?.subtitle_titles).map((val, index) => (
                  <ListItem key={index} className={styles.sublistElement} >
                    <a href={`#${val}`}> {val}</a>
                  </ListItem>
                ))}
                  </List>
                  </Collapse>
                </List>
               
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{ __html: blog.detailed_description }}
              className={styles.descriptionContainer}
            ></div>
            {subtitles &&
              subtitles.map((val, index) => (
                <div id={val} className={styles.subtitleContainer}>
                  <h3>{val}</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: subTitleDesc[index] }}
                  ></div>
                </div>
              ))}
            <h3 style={{ color: "#44ccff" }}>Summary</h3>
            <div className={styles.summaryContainer}>
              <div dangerouslySetInnerHTML={{ __html: blog.summary }}></div>
            </div>
            <div className={styles.socialContainer}>
              <div className={styles.likeContainer}>
                <span>
                  <IconButton
                  className={styles.likeIconButton}
                    sx={{
                      color: "#333333",
                    }}
                    onClick={() => {
                      onLikeClick();
                    }}
                  >
                    {like ? (
                      <ThumbUp  sx={{ width: (!isDesktopDevice)?"1rem":"1.026vw", height: (!isDesktopDevice)?"1rem":"1.026vw" }} />
                    ) : (
                      <ThumbUpOutlined
                      sx={{ width: (!isDesktopDevice)?"1rem":"1.026vw", height: (!isDesktopDevice)?"1rem":"1.026vw" }}
                      />
                    )}
                  </IconButton>
                  {blog.like_count}
                </span>
                <span>
                  <IconButton
                  className={styles.likeIconButton}
                    sx={{
                      color: "#333333",
                    }}
                    onClick={() => {
                      onDislikeClick();
                    }}
                  >
                    {dislike ? (
                      <ThumbDown sx={{ width: (!isDesktopDevice)?"1rem":"1.026vw", height: (!isDesktopDevice)?"1rem":"1.026vw" }} />
                    ) : (
                      <ThumbDownOutlined
                      sx={{ width: (!isDesktopDevice)?"1rem":"1.026vw", height: (!isDesktopDevice)?"1rem":"1.026vw" }}
                      />
                    )}
                  </IconButton>
                  {blog.dislike_count}
                </span>
              </div>
              <div className={styles.share}>
                {isDesktopDevice ?"Share this Article":<ShareIcon sx={{height:"1rem", width:"1rem"}} />}
                <span>
                  <ContentCopyIcon
                    sx={{ width: (!isDesktopDevice)?"1rem":"1.026vw", height: (!isDesktopDevice)?"1rem":"1.026vw" }}
                  />
                </span>
                <a href="https://www.instagram.com/smartsa.in?igsh=Y3gxbzY0c2QyZ3d2">
                  <img src={InstaIcon} alt="Instagram" />
                </a>
                <a href="https://www.linkedin.com/company/smartsa-india/">
                  <img src={LinkedinIcon} alt="Linkedin" />
                </a>
                <a href="https://m.facebook.com/profile.php/?id=100086846304635&name=xhp_nt__fb__action__open_user">
                  <img src={FacebookIcon} alt="Facebook" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsDetails;
