import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import UnAuthNavDesktop from '../../components/Navbar/UnAuthNavDesktop';
import styles from './LabDetails.module.css';
import { ChevronLeft } from "@mui/icons-material";
import image1 from '../../assets/SeoPageImages/lab-page/image1.png';
import image2 from '../../assets/SeoPageImages/lab-page/image2.png';
import image3 from '../../assets/SeoPageImages/lab-page/image3.png';
import image4 from '../../assets/SeoPageImages/lab-page/image4.png';
import image5 from '../../assets/SeoPageImages/lab-page/image5.png';

const LabDetails = () => {
    const { title } = useParams();
    const navigate = useNavigate();
    const cardData = [
        {
            id: 1,
            title: 'Elementary',
            subtitle: 'Elementary Lab: Check Your Math Foundations for Class 9 & 10 Success',
            paratitle1: 'Introduction',
            content: 'Content for Elementary',
            para1: 'The Elementary Lab is designed to assess the foundational mathematics skills crucial for success in class 9 and 10, across all education boards in India. This lab asks questions from lower grades, ensuring that students have solid foundations in key concepts. Weaknesses in these basics often cause students to struggle in higher classes. Through this test, we identify areas of concern and strengths, from integers and decimals to algebraic equations and word problems.',
            paratitle2: 'Why it is Important?',
            para2: 'Foundational math concepts are the building blocks for more advanced topics taught in higher grades. If a student has gaps in these basics, they will likely face difficulties in understanding and applying more complex concepts. Addressing these gaps early is crucial, as it sets the stage for academic success in mathematics and related subjects. This lab is important because it ensures that students are well-prepared to tackle the challenges of higher education and competitive exams.',
            paratitle3: 'What Does it Help Us With?',
            para3: 'The Elementary Lab helps identify specific areas where a student may be struggling, allowing for targeted interventions. By focusing on the foundational concepts, the test helps students build confidence in their math abilities, ensuring they are well-equipped for the more advanced topics that lie ahead. It also highlights their strengths, providing a clear picture of where they excel and where they need improvement.',
            paratitle4: 'Usefulness for School-Going Students',
            para4: 'For school-going students, this lab is incredibly useful both academically and in daily life. Academically, it prepares them for the rigorous demands of class 9 and 10 math, making complex topics more approachable. In day-to-day life, strong math skills are essential for practical tasks such as budgeting, shopping, and understanding interest rates. By solidifying these skills early, students can perform better in school and make more informed decisions in everyday situations.',
            paratitle5: 'Career Choices and Future Opportunities',
            para5: 'A strong foundation in math opens doors to a wide range of career opportunities. Whether a student aspires to pursue science, commerce, arts, or join the defense forces, strong math skills are essential. For competitive exams like Bank PO, SSC, and others, math is a critical component. By mastering these foundational concepts early, students enhance their chances of success in their chosen career paths.',
            paratitle6: 'Usefulness for Non-Working Adults',
            para6: 'Even for non-working adults, strong math foundations are valuable in day-to-day life. From managing household finances to making sense of investment options, math plays a crucial role. It helps in understanding bills, calculating discounts, and planning budgets. By strengthening these foundational skills, adults can make more informed decisions, leading to better financial management and overall life satisfaction.',
            paratitle7: 'Hurry Up!',
            para7: 'Dont let weak foundations hold you back from achieving your full potential. The Elementary Lab is your first step towards mastering the essential math skills that will support your academic success and daily life. Whether youre a student preparing for higher studies or an adult looking to sharpen your skills, this test will guide you in the right direction. Take the test today and start building a stronger, more confident future!',
            image: image1
        },
        {
            id: 2,
            title: 'Secondary',
            subtitle: 'Secondary Lab: Solidify Your Math Basics for Commerce and Arts Pathways',
            paratitle1: 'Introduction',
            para1: 'The Secondary Lab is specifically designed to assess the foundational mathematics skills needed for students pursuing commerce and arts streams in classes 11 and 12, as well as college students enrolled in various degree programs at Indian universities. This lab revisits concepts from lower grades to ensure that students have a solid grounding in essential math topics. Weaknesses in these areas can hinder students ability to excel in higher studies, especially in subjects related to commerce and arts. The test covers a range of topics from integers and decimals to algebraic equations and word problems, which are integral to competitive exams and job aptitude tests.',
            paratitle2: 'Why it is Important?',
            para2: 'A strong foundation in mathematics is critical for students in commerce and arts, as it forms the backbone of many subjects they will encounter in higher education and their professional lives. Concepts such as percentages, algebra, and exponents are not just academic requirements but are also practical skills needed in everyday decision-making and problem-solving. The Secondary Lab is crucial because it identifies gaps in understanding and strengthens these foundational skills, ensuring students are well-prepared for the challenges of advanced studies and competitive exams.',
            paratitle3: 'What Does it Help Us With?',
            para3: 'The Secondary Lab helps in pinpointing specific areas where students may struggle, allowing for targeted learning and improvement. It builds confidence by reinforcing the essential math skills that students need to succeed in their academic journeys and beyond. By highlighting strengths and areas of concern, this lab provides a clear roadmap for improvement, ensuring that students can focus on the topics that matter most to their academic and career aspirations.',
            paratitle4: 'Usefulness for School-Going Students',
            para4: 'For students in classes 11 and 12, the Secondary Lab is invaluable both academically and in their daily lives. Academically, it prepares them for the rigorous demands of their chosen streams, whether in commerce or arts, by ensuring they have a firm grasp of the foundational math concepts. In everyday life, strong math skills are essential for tasks such as budgeting, calculating discounts, and making informed financial decisions. By solidifying these skills now, students set themselves up for success in both their studies and personal lives.',
            paratitle5: 'Career Choices and Future Opportunities',
            para5: 'Strong math foundations open up numerous career opportunities, especially in fields related to commerce and arts. Whether a student is aiming for a career in finance, business, law, or even creative arts, math skills are indispensable. For competitive exams like CAT, CLAT, Bank PO, SSC, and others, a solid grasp of foundational math is a key advantage. By mastering these concepts early on, students enhance their chances of success in their chosen fields and competitive exams, giving them a significant edge in their careers.',
            paratitle6: 'Usefulness for Non-Working Adults',
            para6: 'Even for non-working adults, having strong math foundations is beneficial in everyday life. Whether it’s managing household finances, planning for investments, or simply making informed decisions, math plays a crucial role. The Secondary Lab helps in brushing up on these essential skills, allowing adults to approach daily tasks with greater confidence and competence. Strengthening these foundational skills leads to better financial management, problem-solving abilities, and overall life satisfaction.',
            paratitle7: 'Hurry Up!',
            para7: 'Dont let weak math foundations limit your potential. The Secondary Lab is your gateway to mastering the math skills essential for success in commerce, arts, and beyond. Whether you’re a student aiming for top marks or an adult looking to sharpen your skills, this test will guide you on the path to excellence. Take the test today and secure a brighter, more confident future!',
            image: image2
        },
        {
            id: 3,
            title: 'Advance',   
            subtitle: 'Advanced Lab: Solidify Your Math Core for Engineering and Scientific Excellence',
            paratitle1: 'Introduction',
            para1: 'The Advanced Lab is designed to evaluate and reinforce the foundational mathematics skills that are critical for students pursuing science streams in classes 11 and 12, as well as those enrolled in various engineering and basic science degree programs at Indian universities. This lab revisits essential concepts from lower grades to ensure students have a robust mathematical foundation. Many students struggle in advanced studies due to weak basics, which this test aims to identify and strengthen. The lab covers a wide range of topics from integers and decimals to algebraic equations and word problems, all of which are crucial for competitive exams and job aptitude tests.',
            paratitle2: 'Why it is Important?',
            para2: 'In the field of science and engineering, mathematics is the universal language. A solid foundation in math is essential for understanding complex scientific concepts and solving technical problems. The Advanced Lab is important because it helps students identify gaps in their foundational knowledge, which are often the root cause of struggles in higher education. By addressing these gaps early, students are better prepared for the rigorous demands of advanced studies and competitive exams like GATE, GRE, and others.',
            paratitle3: 'What Does it Help Us With?',
            para3: 'The Advanced Lab helps students identify specific areas where they may need improvement, allowing them to focus their efforts on strengthening these weak points. By solidifying foundational concepts, the lab helps students build the confidence and skills necessary to tackle more complex topics in their academic and professional careers. It also provides insights into their strengths, enabling them to leverage these in their studies and exams.',
            paratitle4: 'Usefulness for School-Going Students',
            para4: 'For students in classes 11 and 12, the Advanced Lab is crucial both academically and in their daily lives. Academically, it prepares them for the intense mathematical demands of science and engineering subjects. In daily life, strong math skills are invaluable for tasks such as problem-solving, logical reasoning, and making informed decisions. By mastering these foundational skills, students can excel in their studies and handle practical challenges with ease.',
            paratitle5: 'Career Choices and Future Opportunities',
            para5: 'Strong math foundations are the gateway to a wide range of career opportunities in engineering, basic sciences, and beyond. Whether a student aims to crack competitive exams like GATE, GRE, CAT, or SSC CGL, or pursue a career in defense or scientific research, a solid grasp of foundational math is indispensable. The Advanced Lab ensures that students are well-prepared to meet these challenges, giving them a significant advantage in their academic and career pursuits.',
            paratitle6: 'Usefulness for Non-Working Adults',
            para6: 'Even for non-working adults, strong math foundations are crucial in everyday life. Whether it’s managing finances, understanding technical information, or solving everyday problems, math plays a vital role. The Advanced Lab helps refresh these essential skills, enabling adults to approach daily tasks with greater confidence and efficiency. Strengthening these foundational skills leads to better decision-making, problem-solving abilities, and overall life satisfaction.',
            paratitle7: 'Hurry Up!',
            para7: 'Don’t let weak math foundations hinder your success. The Advanced Lab is your pathway to mastering the essential math skills needed for a successful career in science, engineering, and beyond. Whether you’re a student aiming for top results or an adult looking to sharpen your skills, this test will guide you toward excellence. Take the test today and build a stronger, more confident future!',
            image: image5
        },
    ];

    const decodedTitle = decodeURIComponent(title);
    const selectedCard = cardData.find(card => card.title === decodedTitle);

    if (!selectedCard) {
        return <div>Card not found</div>;
    }

    const handleLinkClick = (title) => {
        navigate(`/lab-page`);
    };

    return (
        <>
            <UnAuthNavDesktop />
            <div className={styles.learnSidenav}>
                <a href="#all">Table of Contents</a>
                <a href="#intro">Introduction</a>
                <a href="#lorem2">Why it is Important?</a>
                <a href="#lorem3">What Does it Help Us With?</a>
                <a href="#lorem4">Usefulness for School-Going Students</a>
                <a href="#lorem5">Usefulness for Non-Working Adults</a>
                <a href="#summary">Summary</a>
            </div>
            <div className={styles.pageWrapper}>
                <div
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '1.026vw', cursor: 'pointer' }}
                    onClick={handleLinkClick}
                >
                    <ChevronLeft /> Back
                </div>
                <div className={styles.pageTitle}>{selectedCard.subtitle}</div>
                <div>
                    <img
                        src={selectedCard.image}
                        style={{ width: '44.487vw', height: '30.769vw' }}
                        alt={selectedCard.subtitle}
                    />
                </div>
                <div style={{ marginTop: '2.051vw' }}>
                    <div className={styles.paraDiv} id="lorem2">
                        <div className={styles.subTitle} id="intro">{selectedCard.paratitle1}</div>
                            <p className={styles.paraStyle}>{selectedCard.para1}</p>
                        </div>
                        <div className={styles.paraDiv} id="lorem2">
                        <div className={styles.subTitle}>{selectedCard.paratitle2}</div>
                            <p className={styles.paraStyle}>{selectedCard.para2}</p>
                        </div>
                        <div className={styles.paraDiv} id="lorem2">
                        <div className={styles.subTitle}>{selectedCard.paratitle3}</div>
                            <p className={styles.paraStyle}>{selectedCard.para3}</p>
                        </div>
                        <div className={styles.paraDiv} id="lorem2">
                        <div className={styles.subTitle}>{selectedCard.paratitle4}</div>
                            <p className={styles.paraStyle}>{selectedCard.para4}</p>
                        </div>
                        <div className={styles.paraDiv} id="lorem2">
                        <div className={styles.subTitle}>{selectedCard.paratitle5}</div>
                            <p className={styles.paraStyle}>{selectedCard.para5}</p>
                        </div>
                        <div className={styles.paraDiv} id="lorem2">
                        <div className={styles.subTitle}>{selectedCard.paratitle6}</div>
                            <p className={styles.paraStyle}>{selectedCard.para6}</p>
                        </div>
                        <div className={styles.paraDiv} id="summary">
                        <div className={styles.subTitle}>{selectedCard.paratitle7}</div>
                            <p className={styles.paraStyle}>{selectedCard.para7}</p>
                        </div>
                </div>
            </div>
        </>
    );
}

export default LabDetails;