import React, { useState } from "react";
import styles from "./UnauthNav.module.css";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { ClickAwayListener, IconButton } from "@mui/material";
import NewSmartsaLogo from "../../assets/smartsa_new_logo.svg";

const UnauthNav = () => {
  const [open, setOpen] = useState(false);
  const onMenuClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  console.log(open);

  return (
    <>
      <div className={styles.mainHeader}>
        <div className={styles.leftContainer}>
          <div className={styles.menuButtonContainer}>
            <IconButton
              style={{
                backgroundColor: "#000",
                color: "#ffffff",
                padding: "0",
              }}
              onClick={onMenuClick}
              size="small"
              color="default"
            >
              <MenuIcon fontSize="small" />
            </IconButton>
          </div>

              <Link to={"/"} >
          <img src={NewSmartsaLogo} alt="Logo" />
          </Link>
        </div>
        <div className={styles.rightContainer}>
          <NavLink to={"/blogs"}>Blogs</NavLink>
          <NavLink to={"/help"}>Help?</NavLink>
        </div>
      </div>
      {open && (
        <ClickAwayListener onClickAway={onClose}>
          <div
            className={`${styles.MenuContainer} ${open ? styles.isOpen : ""}`}
          >
            <div className={styles.menuLogoContaier}>
              <Link to={"/"} >
              <img src={NewSmartsaLogo} alt="MenuLogo" />
              </Link>
            </div>
            <ul>
              <li key={1}>
                <NavLink to={"/learn"}>Learn</NavLink>
              </li>
              <li key={2}>
                <NavLink to={"/lab-page"}>Labs</NavLink>{" "}
              </li>
              <li key={3}>
                <NavLink to={"/aboutus"}>About Us</NavLink>
              </li>
              <li key={4}>
                <NavLink to={"/"}>Our Story</NavLink>
              </li>
              <li key={5}>
                <NavLink to={"/how-it-works"}>How it Works</NavLink>
              </li>
              <li key={6}>
                <NavLink to={"/help"}>Contact Us</NavLink>
              </li>
              <li key={7}>
                <NavLink to={"/"}>Login</NavLink>
              </li>
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

export default UnauthNav;
