import UnAuthNavDesktop from "../../components/Navbar/UnAuthNavDesktop";
import styles from './LearnPageDetails.module.css'
import { ChevronLeft } from "@mui/icons-material";
import image1 from '../../assets/learn-page-images/image1.png'
import image2 from "../../assets/learn-page-images/image2.png"
import image3 from "../../assets/learn-page-images/image3.png"
import image4 from "../../assets/learn-page-images/image4.png"
import image5 from "../../assets/learn-page-images/image5.png"
import image6 from "../../assets/learn-page-images/image6.png"
import { useNavigate, useParams } from "react-router-dom";

const cardContent =  {
    'Computational Skills': {
               title: 'Computational Skills',
               image: image1,
               paratitle1: 'What is the course “Computational Skills” about?',
               paragraph1: 'Computational skills are the abilities required to solve mathematical problems efficiently and accurately. These skills include basic arithmetic operations like addition, subtraction, multiplication, and division, as well as understanding fractions, decimals, percentages, ratios, and basic algebraic concepts. In essence, computational skills form the foundation of mathematics and are essential for understanding more complex mathematical concepts. They are the building blocks that enable students to solve problems, analyze data, and make informed decisions in both academic settings and everyday life.',
               paratitle2: 'Why is it Important?',
               paragraph2: 'Computational skills are crucial for success in both academic and real-world scenarios. They form the foundation of logical thinking, problem-solving, and decision-making. Whether you are solving a simple math problem, managing a budget, or analyzing data, strong computational skills are essential. They enable individuals to approach problems systematically, break them down into manageable steps, and arrive at accurate solutions. In the fast-paced world we live in, where data and numbers are everywhere, having strong computational skills is not just important—its indispensable.',
               paratitle3: 'What Does it Do?',
               paragraph3: 'Computational skills empower individuals to process numerical information efficiently and accurately. They enhance ones ability to perform calculations, interpret data, and apply mathematical concepts to solve real-world problems. For students, these skills are fundamental in subjects like mathematics, physics, and economics. For professionals, they are critical in fields such as finance, engineering, and data analysis. Essentially, computational skills bridge the gap between theoretical knowledge and practical application, allowing individuals to make informed decisions and solve problems effectively.',
               paratitle4: 'Who is this course for?',
               paragraph4: 'This course is perfect for students in grades 9 to 12 from all boards, including CBSE, ICSE, Cambridge, and state boards. Its also a great fit for college students in any stream—science, commerce, or arts—who are preparing for job placements, entrance exams like IIT JEE, CET, NEET, CAT, CLAT, CSIR-NET, NDA and NID, or competitive exams such as UPSC, SSC, and banking exams. If youre a student looking to boost your exam scores or a college-goer aiming for success in your career, this course is designed to give you the essential skills to excel in academic and professional endeavors. Whether in school, college, or getting ready for the next big step, this course will help you achieve your goals.',
               paratitle5: 'Usefulness for School-Going Students',
               paragraph5: 'For school-going students, computational skills are the backbone of academic success in mathematics and science. These skills help students understand and solve problems in their textbooks, prepare for exams, and develop a strong foundation for higher education. Beyond academics, computational skills are useful in day-to-day activities, such as managing pocket money, planning time, or understanding measurements. By mastering these skills early on, students build the confidence and competence needed to tackle more complex subjects in the future.',
               paratitle6: 'Usefulness for College-Going Students',
               paragraph6: 'For college-going students, computational skills are crucial for academic success in courses that involve mathematics, statistics, or data analysis. These skills are also invaluable in disciplines like engineering, economics, and computer science, where precise calculations and logical reasoning are essential. In everyday life, computational skills help college students manage their finances, plan their studies, and make informed decisions. Mastering these skills not only enhances academic performance but also prepares students for the challenges of the professional world.',
               paratitle7: 'Usefulness for Working Professionals and Businessmen',
               paragraph7: 'For working professionals and businessmen, computational skills are indispensable. Whether its analyzing financial data, preparing reports, or making strategic business decisions, these skills enable professionals to process information accurately and efficiently. In the workplace, strong computational skills translate to better problem-solving abilities, more effective communication, and improved productivity. For businessmen, these skills are essential for managing budgets, evaluating investments, and ensuring the financial health of their enterprises. In day-to-day life, computational skills help individuals manage their finances, plan their time, and make informed choices.',
               paratitle8: 'Usefulness for Non-Working Adults',
               paragraph8: 'For non-working adults, computational skills remain highly relevant in everyday life. From managing household budgets and expenses to making informed decisions about purchases or investments, these skills are essential. They also help in understanding and interpreting information, such as health data or financial statements, that one encounters regularly. Even in retirement or non-professional settings, strong computational skills contribute to a more organized, efficient, and informed lifestyle. Learning and refining these skills can lead to greater independence and confidence in managing ones affairs.'
           },
    'GodSpeed': {
               title: 'God Speed',
               image: image2,
               paratitle1: 'What is the course "God Speed" about?',
               paragraph1: '"God Speed" is a course designed to enhance your understanding of fundamental mathematical concepts such as fractions, decimals, and percentages, while simultaneously improving your calculation speed. This course is crafted to build competence in these essential areas of mathematics, which are critical for academic success and practical day-to-day applications. By focusing on both accuracy and speed, "God Speed" ensures that students not only master these concepts but also develop the ability to apply them swiftly in various situations.',
               paratitle2: 'Why is it Important?',
               paragraph2: 'Understanding fractions, decimals, and percentages is fundamental to mathematics and is crucial for solving problems in everyday life. Whether you are calculating discounts, managing budgets, or understanding data, these concepts are integral. "God Speed" emphasizes not only learning these concepts but also improving the speed at which you can perform calculations. In today’s fast-paced world, the ability to quickly and accurately process numerical information is a vital skill, making this course both important and relevant for students and professionals alike.',
               paratitle3: 'What Does it Do?',
               paragraph3: '"God Speed" enhances both the understanding and application of fractions, decimals, and percentages through a series of focused exercises. It trains individuals to perform calculations swiftly without compromising accuracy. The course employs practical examples and real-life scenarios to demonstrate the utility of these concepts, helping learners apply them in various contexts. By the end of the course, participants will be able to handle mathematical challenges more efficiently and with greater confidence.',
               paratitle4: 'Who is this course for?',
               paragraph4: 'This course is perfect for students in grades 9 to 12 from all boards, including CBSE, ICSE, Cambridge, and state boards. It is also a great fit for college students in any stream—science, commerce, or arts—who are preparing for job placements, entrance exams like IIT JEE, CET, NEET, CAT, CLAT, CSIR-NET, NDA and NID, or competitive exams such as UPSC, SSC, and banking exams. If you are a student looking to boost your exam scores or a college-goer aiming for success in your career, this course is designed to give you the essential skills to excel in academic and professional endeavors. Whether in school, college, or getting ready for the next big step, this course will help you achieve your goals.',
               paratitle5: 'Usefulness for School-Going Students',
               paragraph5: 'For school-going students, mastering fractions, decimals, and percentages is essential for excelling in mathematics. "God Speed" helps students build a strong foundation in these areas, which are frequently tested in exams and form the basis for more advanced topics. Additionally, the focus on speed aids in improving their performance during timed tests, boosting their confidence and academic success. Beyond academics, these skills are useful in everyday situations such as calculating discounts, splitting bills, and managing allowances.',
               paratitle6: 'Usefulness for College-Going Students',
               paragraph6: 'College-going students benefit from "God Speed" as it strengthens their mathematical skills, particularly in fields that require precise calculations, such as economics, engineering, and data analysis. The speed training component of the course ensures that students can solve problems quickly, an invaluable skill during exams and in professional scenarios. These skills also enhance their ability to manage personal finances, understand statistical data, and perform tasks that involve numerical analysis in their day-to-day lives.',
               paratitle7: 'Usefulness for Working Professionals and Businessmen',
               paragraph7: 'For working professionals and businessmen, the ability to quickly and accurately work with fractions, decimals, and percentages is indispensable. Whether it’s analyzing financial statements, making data-driven decisions, or managing projects, these skills are critical. "God Speed" not only strengthens these mathematical abilities but also enhances speed, making professionals more efficient and effective in their roles. In the fast-paced business environment, the capacity to process numerical information swiftly can provide a significant competitive advantage.',
               paratitle8: 'Usefulness for Non-Working Adults',
               paragraph8: 'For non-working adults, "God Speed" offers valuable skills that can be applied in various aspects of daily life. From managing household budgets to understanding financial offers or making informed purchasing decisions, the ability to quickly and accurately work with fractions, decimals, and percentages is essential. This course not only improves numerical proficiency but also builds confidence in handling day-to-day tasks that require mathematical thinking, ensuring a more organized and informed lifestyle.'
           },
   'Heads and Huts': {
               title: 'Heads and Huts',
               image: image3,
               paratitle1: 'What is the course "Heads and Huts" about?',
               paragraph1: '"Heads & Huts" is a comprehensive course designed to strengthen your understanding of essential mathematical concepts such as BODMAS, exponents, radicals, powers, indices, and square roots. These topics are fundamental to mathematics and are used in a variety of complex calculations and problem-solving scenarios. The course is structured to help learners grasp these concepts clearly and apply them confidently in both academic and practical situations.',
               paratitle2: 'Why is it Important?',
               paragraph2: 'Understanding BODMAS (Bracket, Order, Division/Multiplication, Addition/Subtraction), exponents, and related concepts is crucial for solving complex mathematical problems accurately. These concepts form the backbone of algebra and are widely used in various fields such as science, engineering, and finance. "Heads & Huts" ensures that learners not only understand these topics but also become adept at using them, thus laying a strong foundation for advanced studies and practical applications. In todays data-driven world, mastering these skills is not just important—its essential.',
               paratitle3: 'What Does it Do?',
               paragraph3: '"Heads & Huts" enhances your ability to work with mathematical expressions involving BODMAS, exponents, radicals, and more. Through targeted exercises and examples, the course builds proficiency in handling calculations that involve complex order of operations, powers, and roots. By the end of the course, learners will be equipped to tackle mathematical challenges efficiently and with a deeper understanding of the underlying principles. This course bridges the gap between theoretical knowledge and practical application, making it easier to solve real-world problems.',
               paratitle4: 'Who is this course for?',
               paragraph4: 'This course is perfect for students in grades 9 to 12 from all boards, including CBSE, ICSE, Cambridge, and state boards. Its also a great fit for college students in any stream—science, commerce, or arts—who are preparing for job placements, entrance exams like IIT JEE, CET, NEET, CAT, CLAT, CSIR-NET, NDA and NID, or competitive exams such as UPSC, SSC, and banking exams. If you are a student looking to boost your exam scores or a college-goer aiming for success in your career, this course is designed to give you the essential skills to excel in academic and professional endeavors. Whether in school, college, or getting ready for the next big step, this course will help you achieve your goals.',
               paratitle5: 'Usefulness for School-Going Students',
               paragraph5: 'For school-going students, "Heads & Huts" is invaluable in mastering mathematical operations that are frequently tested in exams. BODMAS, exponents, and square roots are integral parts of the school curriculum and are foundational for higher mathematics. This course helps students develop a strong grasp of these topics, boosting their confidence and performance in class. Beyond academics, these skills are useful in everyday tasks such as calculating measurements, understanding scientific data, and managing finances.',
               paratitle6: 'Usefulness for College-Going Students',
               paragraph6: 'For college-going students, especially those pursuing studies in science, engineering, economics, or technology, "Heads & Huts" is essential. The course strengthens their ability to work with complex mathematical expressions, which is critical for success in advanced courses and research. By improving their understanding and speed in handling these concepts, students can achieve better academic outcomes and be better prepared for professional challenges. Additionally, these skills are beneficial in managing personal finances and analyzing data in day-to-day life.',
               paratitle7: 'Usefulness for Working Professionals and Businessmen',
               paragraph7: 'For working professionals and businessmen, the ability to quickly and accurately apply BODMAS, exponents, and related concepts is vital. Whether it’s analyzing financial reports, conducting statistical analysis, or solving technical problems, these skills are indispensable. "Heads & Huts" equips professionals with the mathematical tools needed to excel in their fields, enhancing their problem-solving capabilities and improving their efficiency at work. In a fast-paced business environment, proficiency in these areas can provide a significant edge.',
               paratitle8: 'Usefulness for Non-Working Adults',
               paragraph8: 'For non-working adults, "Heads & Huts" offers practical skills that can be applied in various aspects of daily life. From managing household budgets and understanding interest rates to interpreting scientific information and making informed decisions, this course enhances numerical literacy. Learning and refining these mathematical concepts lead to greater confidence and independence in handling everyday tasks, ensuring a more organized and informed lifestyle.'
           },
   'Knowns and Unknowns': {
               title: 'Knowns and Unknowns',
               image: image2,
               paratitle1: 'What is the course "Knowns and Unknowns" about?',
               paragraph1: '"Knowns & Unknowns" is a foundational course in introductory Algebra, designed to help learners grasp essential algebraic concepts. The course covers algebraic expressions, algebraic identities, algebraic operations, and the solution of algebraic equations. These topics are fundamental to understanding how algebra works and are essential for progressing to more advanced mathematical studies. This course is structured to make algebra approachable and understandable, enabling learners to apply these concepts in both academic settings and real-world situations.',
               paratitle2: 'Why is it Important?',
               paragraph2: 'Algebra forms the cornerstone of modern mathematics and is used in various fields such as science, engineering, economics, and technology. Understanding algebraic expressions, identities, and equations is crucial for solving complex problems and making informed decisions. "Knowns & Unknowns" provides a solid foundation in these concepts, helping learners develop logical thinking and problem-solving skills. Mastering algebra is not just important for academic success; it also equips individuals with the tools needed to tackle everyday challenges that involve mathematical reasoning.',
               paratitle3: 'What Does it Do?',
               paragraph3: '"Knowns & Unknowns" builds proficiency in algebra through targeted exercises that focus on understanding and manipulating algebraic expressions, applying algebraic identities, performing algebraic operations, and solving algebraic equations. The course is designed to demystify algebra, breaking down complex concepts into manageable steps. By the end of the course, learners will be able to confidently handle algebraic problems and apply these skills in various contexts, from academic exams to practical problem-solving scenarios.',
               paratitle4: 'Who is this course for?',
               paragraph4: 'This course is perfect for students in grades 9 to 12 from all boards, including CBSE, ICSE, Cambridge, and state boards. It is also a great fit for college students in any stream—science, commerce, or arts—who are preparing for job placements, entrance exams like IIT JEE, CET, NEET, CAT, CLAT, CSIR-NET, NDA and NID, or competitive exams such as UPSC, SSC, and banking exams. If you are a student looking to boost your exam scores or a college-goer aiming for success in your career, this course is designed to give you the essential skills to excel in academic and professional endeavors. Whether in school, college, or getting ready for the next big step, this course will help you achieve your goals.',
               paratitle5: 'Usefulness for School-Going Students',
               paragraph5: 'For school-going students, "Heads & Huts" is invaluable in mastering mathematical operations that are frequently tested in exams. BODMAS, exponents, and square roots are integral parts of the school curriculum and are foundational for higher mathematics. This course helps students develop a strong grasp of these topics, boosting their confidence and performance in class. Beyond academics, these skills are useful in everyday tasks such as calculating measurements, understanding scientific data, and managing finances.',
               paratitle6: 'Usefulness for College-Going Students',
               paragraph6: 'For college-going students, especially those pursuing degrees in science, engineering, economics, or computer science, "Knowns & Unknowns" is indispensable. The course strengthens their ability to work with algebraic expressions and equations, which are crucial for advanced studies and research. Understanding and applying algebraic concepts is also beneficial in courses that involve data analysis, programming, and financial modeling. This course ensures that students are well-prepared for the mathematical challenges they will encounter in their academic and professional lives.',
               paratitle7: 'Usefulness for Working Professionals and Businessmen',
               paragraph7: 'For working professionals and businessmen, a solid understanding of algebra can be a significant asset. Whether it’s analyzing data, creating financial models, or solving technical problems, algebraic skills are essential. "Knowns & Unknowns" equips professionals with the ability to work with algebraic equations and expressions, enhancing their problem-solving capabilities and improving efficiency in the workplace. In business, algebraic reasoning can lead to better decision-making and a more systematic approach to challenges.',
               paratitle8: 'Usefulness for Non-Working Adults',
               paragraph8: 'For non-working adults, "Knowns & Unknowns" offers valuable skills that can be applied in various aspects of daily life. From managing personal finances to understanding data and making informed decisions, algebra plays a crucial role. This course helps non-working adults build their confidence in handling mathematical problems, ensuring a more organized and logical approach to everyday tasks. Whether it’s planning a budget or interpreting information, algebraic skills are highly beneficial.'
           },
    'Defence Specialization': {
               title: 'Defence Specialization: A Strong Foundation for Future Success',
               image: image5,
               paratitle1: 'Introduction',
               paragraph1: 'The "Defence" specialization is designed to equip students of class (grades) 6 and 7 with a strong foundation in mathematics, ensuring they are well-prepared for future challenges. The name "Defence" reflects the course’s aim to proactively prepare students, reducing the likelihood of math-related struggles later in their academic journey. By mastering essential math skills early on, students can confidently progress in their education without the need for remedial help in the future.',
               paratitle2: 'What Math Topics Are Covered?',
               paragraph2: 'This specialization covers a broad range of fundamental math topics essential for young learners. The courses include:Computational Skills: Basic arithmetic operations, fractions, decimals, percentages, and their real-life applications.God Speed: Mastery of BODMAS, exponents, powers, and square roots with a focus on accuracy and speed. Heads & Huts: Introduction to algebraic expressions, identities, and solving simple equations.Knowns & Unknowns: Building a strong foundation in algebra, covering algebraic operations and the unitary method. Word Wizard: Enhancing problem-solving skills through unit conversions, the unitary method, and word problems.',
               paratitle3: 'Which Education Boards Does It Support?',
               paragraph3: 'The "Defence" specialization is tailored for students across all educational boards in India, including CBSE, ICSE, Cambridge, and state boards. The curriculum is aligned with the standards of each board, ensuring that the material is relevant and beneficial for all students.',
               paratitle4: 'How Is It Useful for Class (Grade) 6 and 7 Students?',
               paragraph4: 'For students in class (grades) 6 and 7, this specialization lays the groundwork for advanced math concepts that they will encounter in higher classes. By mastering these foundational topics early, students gain confidence and a deep understanding of math, making future learning smoother and more enjoyable. The skills learned in "Defence" are not just academic—they are tools for life, helping students approach problems logically and effectively.',
               paratitle5: 'How Does It Solve Parents Problems?',
               paragraph5: 'Parents often worry about their children falling behind in math, which can lead to challenges in later years. The "Defence" specialization addresses these concerns by providing a strong mathematical foundation at an early age. This proactive approach helps prevent future struggles, giving parents peace of mind that their children are well-prepared for academic success.',
               paratitle6: 'How Does It Solve Teachers Problems?',
               paragraph6: 'Teachers benefit from the "Defence" specialization as it strengthens students basics, making it easier to teach advanced topics later on. The course also includes tools for creating and auto-checking assignments, allowing teachers to provide immediate feedback and track students progress efficiently. This not only saves time but also ensures that students receive the support they need to succeed.',
           },
    'Rescue Specialization': {
               title: 'Rescue Specialization: Strengthening the Foundation for Future Success',
               image: image6,
               paratitle1: 'Introduction',
               paragraph1: 'The "Rescue" specialization is specifically designed for students in classes 8, 9, and 10 who have advanced in their education but lack a strong foundation in mathematics. The name "Rescue" reflects the course’s mission to help these students catch up and strengthen their foundational math skills. If not addressed now, these gaps in knowledge could lead to significant challenges in higher education and limit future career choices. By providing targeted support, the "Rescue" specialization offers a remedial solution to ensure students are well-prepared for their current and future academic journey.',
               paratitle2: 'What Math Topics Are Covered?',
               paragraph2: 'This specialization covers a broad range of fundamental math topics essential for young learners. The courses include: Computational Skills: Basic arithmetic operations, fractions, decimals, percentages, and their real-life applications. God Speed: Mastery of BODMAS, exponents, powers, and square roots with a focus on accuracy and speed. Heads & Huts: Introduction to algebraic expressions, identities, and solving simple equations. Knowns & Unknowns: Building a strong foundation in algebra, covering algebraic operations and the unitary method. Word Wizard: Enhancing problem-solving skills through unit conversions, the unitary method, and word problems.',
               paratitle3: 'Which Education Boards Does It Support?',
               paragraph3: 'The "Rescue" specialization is designed to support students across all educational boards in India, including CBSE, ICSE, Cambridge, and state boards. The curriculum aligns with the standards of each board, ensuring that students receive the support they need to succeed.',
               paratitle4: 'How Is It Useful for Class (Grade) 8, 9 and 10 Students?',
               paragraph4: 'For students in classes 8, 9, and 10, the "Rescue" specialization is critical in reinforcing foundational math skills that are essential for success in higher classes. By addressing gaps in knowledge now, students can confidently tackle more advanced topics and reduce the risk of struggling in future studies. This course provides the necessary tools to ensure a smoother transition to higher education and keeps future career options open.',
               paratitle5: 'How Does It Solve Parents Problems?',
               paragraph5: 'Parents often worry when their children struggle with math, knowing that these difficulties could impact their future academic and career prospects. The "Rescue" specialization offers a proactive solution by providing targeted support to strengthen foundational skills. This course helps parents ensure their children are well-equipped to succeed in math, easing concerns about their academic future.',
               paratitle6: 'How Does It Solve Teachers’ Problems?',
               paragraph6: 'Teachers face challenges when students lack the basics needed to understand more complex math topics. The "Rescue" specialization helps by reinforcing foundational skills, making it easier for teachers to introduce advanced concepts. The course also includes tools for creating and auto-checking assignments, allowing teachers to provide immediate feedback and track students progress effectively, ultimately saving time and improving learning outcomes.',
           },
    'Workshop Specialization': {
               title: 'Workshop Specialization: Fast-Track to Math Mastery',
               image: image4,
               paratitle1: 'Introduction',
               paragraph1: 'The "Workshop" specialization is a quick, efficient, and intensive 5-day course designed for students from grades 8 to 12, as well as college students across all fields and majors. This course is tailored for those who have advanced in their education but lack the foundational math knowledge needed for current and future studies. The "Workshop" aims to rescue students from potential math struggles by providing a rapid and effective remedial solution. By addressing these gaps now, students can avoid future academic difficulties and keep their higher education and career options open.',
               paratitle2: 'What Math Topics Are Covered?',
               paragraph2: 'The "Workshop" specialization covers essential math topics critical for building a strong foundation in a compact and focused format, ensuring that students get the most out of their 5-day course: Computational Skills: Basic arithmetic operations, fractions, decimals, percentages, and their real-life applications. God Speed: Mastery of BODMAS, exponents, powers, and square roots with a focus on accuracy and speed. Heads & Huts: Introduction to algebraic expressions, identities, and solving simple equations. Knowns & Unknowns: Building a strong foundation in algebra, covering algebraic operations and the unitary method. Word Wizard: Enhancing problem-solving skills through unit conversions, the unitary method, and word problems.',
               paratitle3: 'Which Education Boards and Universities Does It Support?',
               paragraph3: 'The "Workshop" specialization is designed to support students across all educational boards and universities in India, including CBSE, ICSE, Cambridge, and state boards. It is also ideal for students from major universities such as Delhi University, Mumbai University, Christ University, and Jawaharlal Nehru University, catering to those studying science, commerce, arts, and more.',
               paratitle4: 'How Is It Useful for Class (Grade) 8 to 12 Students?',
               paragraph4: 'For students in grades 8 to 12, the "Workshop" specialization provides a quick and effective way to reinforce foundational math skills. In just 5 days, students can catch up on essential concepts, ensuring they are well-prepared for board exams and competitive entrance tests. This course is particularly valuable for those who need a quick refresher to avoid future struggles in math.',
               paratitle5: 'How is it Useful for College Students?',
               paragraph5: 'For college students, whether they are pursuing degrees in science, commerce, or arts, the "Workshop" specialization offers a targeted approach to strengthen their math foundation. This course is especially useful for those preparing for competitive exams, such as CAT, GRE, GMAT, and government exams, where math proficiency is crucial. The 5-day structure ensures that even busy students can benefit from this intensive training.',
               paratitle6: 'How Does It Solve Parents Problems?',
               paragraph6: 'Parents often worry about their childrens math skills, knowing that gaps in understanding can impact their academic and career prospects. The "Workshop" specialization provides a quick and effective solution, allowing students to rapidly improve their math skills and build confidence. This course offers peace of mind to parents, knowing that their children are on the right track.',
               paratitle7: 'How Does It Solve Teachers Problems?',
               paragraph7: 'Teachers benefit from the "Workshop" specialization as it helps students quickly grasp essential math concepts, making it easier to introduce more advanced topics. The course includes tools for creating and auto-checking assignments, allowing teachers to provide immediate feedback and track students progress efficiently, ultimately saving time and improving learning outcomes.',
               paratitle8: 'How Does It Solve Indias Employment Problem?',
               paragraph8: 'By equipping students and fresh graduates with stronger analytical, logical, and critical thinking skills, the "Workshop" specialization contributes to making them more adaptable to new technologies and work environments. This, in turn, enhances their employability and helps address Indias employment challenges by creating a more capable and versatile workforce.'
           }
   }

const LearnPageDetails = () => {
    const navigate = useNavigate();
    const { title } = useParams();
    const content = cardContent[decodeURIComponent(title)];

    const handleLinkClick = () => {
        navigate('/learn');
    };

    return (
        <>
            <UnAuthNavDesktop />
            <div className={styles.learnSidenav}>
                <a href="#all">Table of Contents</a>
                <a href="#lorem1">Lorem</a>
                <a href="#lorem2">Lorem</a>
                <a href="#lorem3">Lorem</a>
                <a href="#lorem4">Lorem</a>
                <a href="#summary">Summary</a>
            </div>
            <div className={styles.pageWrapper}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        fontSize: '1.026vw',
                        cursor: 'pointer'
                    }}
                    onClick={handleLinkClick}
                >
                    <ChevronLeft /> Back
                </div>
                <div className={styles.pageTitle}>{content?.title}</div>
                <div>
                    <img
                        src={content?.image}
                        style={{
                            width: '44.487vw',
                            height: '30.769vw'
                        }}
                    />
                </div>
                <div style={{ marginTop: '2.051vw' }} id="lorem1">
                    <div className={styles.paraDiv} id="lorem2">
                        <div className={styles.subTitle}>{content?.paratitle1}</div>
                            <p className={styles.paraStyle}>{content?.paragraph1}</p>
                        </div>
                    <div className={styles.paraDiv}>
                        <div className={styles.subTitle} id="lorem3">{content?.paratitle2}</div>
                        <p className={styles.paraStyle}>{content?.paragraph2}</p>
                    </div>
                    <div className={styles.paraDiv}>
                        <div className={styles.subTitle} id="lorem3">{content?.paratitle3}</div>
                        <p className={styles.paraStyle}>{content?.paragraph3}</p>
                    </div>
                    <div className={styles.paraDiv}>
                        <div className={styles.subTitle} id="lorem3">{content?.paratitle3}</div>
                        <p className={styles.paraStyle}>{content?.paragraph4}</p>
                    </div>
                    <div className={styles.paraDiv}>
                        <div className={styles.subTitle} id="lorem3">{content?.paratitle5}</div>
                        <p className={styles.paraStyle}>{content?.paragraph5}</p>
                    </div>
                    <div className={styles.paraDiv}>
                        <div className={styles.subTitle} id="lorem3">{content?.paratitle6}</div>
                        <p className={styles.paraStyle}>{content?.paragraph6}</p>
                    </div>
                    <div className={styles.paraDiv}>
                        <div className={styles.subTitle} id="lorem3">{content?.paratitle7}</div>
                        <p className={styles.paraStyle}>{content?.paragraph7}</p>
                    </div>
                    <div className={styles.paraDiv}>
                        <div className={styles.subTitle} id="lorem3">{content?.paratitle8}</div>
                        <p className={styles.paraStyle}>{content?.paragraph8}</p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default LearnPageDetails;