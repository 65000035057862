import React, { useState } from 'react';
import UnAuthNavDesktop from '../../components/Navbar/UnAuthNavDesktop';
import 'swiper/css';
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import styles from './LabsPage.module.css';
import { useNavigate } from 'react-router-dom';
import image1 from '../../assets/SeoPageImages/lab-page/image1.png'
import image2 from '../../assets/SeoPageImages/lab-page/image2.png'
import image3 from '../../assets/SeoPageImages/lab-page/image3.png'
import image4 from '../../assets/SeoPageImages/lab-page/image4.png'
import image5 from '../../assets/SeoPageImages/lab-page/image5.png'

const LabPage = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');

    const cardData = [
        { id: 1, title: 'Elementary', section: 'all', image: image1 },
        { id: 2, title: 'Secondary', section: 'all', image: image2 },
        { id: 3, title: 'Advance', section: 'all', image: image5 },
        // { id: 4, title: 'IIT JEE', section: 'all', image: image4 },
        { id: 3, title: 'Advance', section: 'coreTest', image: image5 },
        // { id: 6, title: 'IIT JEE', section: 'testSeries', image: image4 },
        // { id: 7, title: 'MBA', section: 'testSeries', image: image3 }
    ];

    const handleLinkClick = (title) => {
        navigate(`/lab-details/${encodeURIComponent(title)}`);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
      };
    
      const filteredData = cardData.filter(item =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );

    return (
        <>
            <UnAuthNavDesktop />
            <div className={styles.labSidenav}>
                <a href="#all">All</a>
                <a href="#CoreTest">Core Test</a>
                <a href="#TestSeries">Test Series</a>
            </div>
            <div id='all' className={styles.pageWrapper}>
                <div className={styles.partOne}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <div className={styles.subHeadings}>All</div>
                        <div>
                            <TextField
                                size="small"
                                style={{
                                    width: "11.35vw",
                                    borderRadius: "0.625rem",
                                    border: '2px solid #333333',
                                    margin: '0 5vw 1vw 0',
                                    color: '#cccccc'
                                }}
                                sx={{ input: { color: '#cccccc' } }}
                                placeholder="Search"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon style={{ color: '#44ccff', fontSize: '1.923vw' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    style: {
                                        paddingRight: '0px'
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.cardContainer}>
                    {filteredData.filter(card => card.section === 'all').map(card => (
                            <Card key={card.id} sx={{
                                width: '24.103vw',
                                backgroundColor: '#2E2D2D25',
                                border: '2PX SOLID #333333'
                            }}>
                                <CardMedia
                                    sx={{ height: '16.795vw' }}
                                    image={card.image}
                                    title={card.title}
                                />
                                <div className={styles.cardBottom}>
                                    <CardContent>
                                        <div className={styles.cardContent}>
                                            {card.title}
                                        </div>
                                    </CardContent>
                                    <CardActions>
                                        <a
                                            style={{
                                                color: "#44CCFF",
                                                cursor: "pointer",
                                            }}
                                            className={styles.readMore}
                                            onClick={() => handleLinkClick(card.title)}
                                        >
                                            Read More &#8594;
                                            <hr className={styles.readMeLine} />
                                        </a>
                                    </CardActions>
                                </div>
                            </Card>
                        ))}
                    </div>
                </div>
                <div id='CoreTest' className={styles.partTwo}>
                    <div className={styles.subHeadings} style={{marginBottom: '1.795vw'}}>Core Test</div>
                    <div className={styles.cardContainer}>
                    {filteredData.filter(card => card.section === 'coreTest').map(card => (
                            <Card key={card.id} sx={{
                                width: '24.103vw',
                                backgroundColor: '#2E2D2D25',
                                border: '2PX SOLID #333333'
                            }}>
                                <CardMedia
                                    sx={{ height: '16.795vw' }}
                                    image={card.image}
                                    title={card.title}
                                />
                                <div className={styles.cardBottom}>
                                    <CardContent>
                                        <div className={styles.cardContent}>
                                            {card.title}
                                        </div>
                                    </CardContent>
                                    <CardActions>
                                        <a
                                            style={{
                                                color: "#44CCFF",
                                                cursor: "pointer",
                                            }}
                                            className={styles.readMore}
                                            onClick={() => handleLinkClick(card.title)}
                                        >
                                            Read More &#8594;
                                            <hr className={styles.readMeLine} />
                                        </a>
                                    </CardActions>
                                </div>
                            </Card>
                        ))}
                    </div>
                </div>
                {/* <div id='TestSeries' className={styles.partThree}>
                    <div className={styles.subHeadings}>Test Series</div>
                    <div className={styles.cardContainer}>
                        {cardData.filter(card => card.section === 'testSeries').map(card => (
                            <Card key={card.id} sx={{
                                width: '24.103vw',
                                backgroundColor: '#2E2D2D25',
                                border: '2PX SOLID #333333'
                            }}>
                                <CardMedia
                                    sx={{ height: '16.795vw' }}
                                    image={card.image}
                                    title={card.title}
                                />
                                <div className={styles.cardBottom}>
                                    <CardContent>
                                        <div className={styles.cardContent}>
                                            {card.title}
                                        </div>
                                    </CardContent>
                                    <CardActions>
                                        <a
                                            style={{
                                                color: "#44CCFF",
                                                cursor: "pointer",
                                            }}
                                            className={styles.readMore}
                                            onClick={handleLinkClick}
                                        >
                                            Read More &#8594;
                                            <hr className={styles.readMeLine} />
                                        </a>
                                    </CardActions>
                                </div>
                            </Card>
                        ))}
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default LabPage;