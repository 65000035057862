import { useNavigate } from 'react-router-dom';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import UnAuthNavDesktop from "../../components/Navbar/UnAuthNavDesktop";
import styles from './LearnPage.module.css';
import 'swiper/css';
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
} from '@mui/material';

import image1 from "../../assets/learn-page-images/image1.png";
import image2 from "../../assets/learn-page-images/image2.png";
import image3 from "../../assets/learn-page-images/image3.png";
import image4 from "../../assets/learn-page-images/image4.png";
import image5 from "../../assets/learn-page-images/image5.png";
import image6 from "../../assets/learn-page-images/image6.png";

const cardData = [
    { id: 'card1', image: image1, title: 'Computational Skills', section: 'skills' },
    { id: 'card2', image: image2, title: 'GodSpeed', section: 'skills' },
    { id: 'card3', image: image3, title: 'Heads and Huts', section: 'skills' },
    { id: 'card4', image: image4, title: 'Knowns and Unknowns', section: 'skills' },
    { id: 'card2', image: image2, title: 'GodSpeed', section: 'all' },
    { id: 'card3', image: image3, title: 'Heads and Huts', section: 'all' },
    { id: 'card4', image: image4, title: 'Knowns and Unknowns', section: 'all' },
    { id: 'card5', image: image5, title: 'Defence Specialization', section: 'specialization' },
    { id: 'card6', image: image5, title: 'Rescue Specialization', section: 'specialization' },
    { id: 'card7', image: image6, title: 'Workshop Specialization', section: 'specialization' },
];

const LearnPage = () => {
    const navigate = useNavigate();

    const handleLinkClick = (title) => {
        navigate(`/learn/${encodeURIComponent(title)}`);
    };

    const renderCards = (section) => {
        return cardData
            .filter(card => card.section === section)
            .map(card => (
                <SwiperSlide key={card.id}>
                    <Card sx={{
                        width: '24.103vw',
                        backgroundColor: '#2E2D2D25',
                        border: '2PX SOLID #333333'
                    }}>
                        <CardMedia
                            sx={{ height: '16.795vw' }}
                            image={card.image}
                            title={card.title}
                        />
                        <div className={styles.cardBottom}>
                            <CardContent>
                                <div className={styles.cardContent}>
                                    {card.title}
                                </div>
                            </CardContent>
                            <CardActions>
                                <a
                                    style={{ color: "#44CCFF", cursor: "pointer" }}
                                    className={styles.readMore}
                                    onClick={() => handleLinkClick(card.title)}
                                >
                                    Read More &#8594;
                                    <hr className={styles.readMeLine} />
                                </a>
                            </CardActions>
                        </div>
                    </Card>
                </SwiperSlide>
            ));
    };

    return (
        <>
            <UnAuthNavDesktop />
            <div className={styles.learnSidenav}>
                <a href="#all">All</a>
                <a href="#specialization">Specialization</a>
                <a href="#skills">Skills</a>
            </div>
            <div className={styles.pageWrapper}>
                <div id='all' className={styles.partOne}>
                    <div className={styles.subHeadings}>All</div>
                    <SwiperComponent
                        spaceBetween={25}
                        slidesPerView={3.2}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        {renderCards('all')}
                    </SwiperComponent>
                </div>
                <div className={styles.partTwo}>
                    <SwiperComponent
                        spaceBetween={25}
                        slidesPerView={3.2}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        {renderCards('specialization')}
                    </SwiperComponent>
                </div>
                <div id='specialization' className={styles.partThree}>
                    <div className={styles.subHeadings}>Specialization</div>
                    <SwiperComponent
                        spaceBetween={25}
                        slidesPerView={3.2}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        {renderCards('specialization')}
                    </SwiperComponent>
                </div>
                <div id='skills' className={styles.partThree}>
                    <div className={styles.subHeadings}>Skills</div>
                    <SwiperComponent
                        spaceBetween={25}
                        slidesPerView={3.2}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        {renderCards('skills')}
                    </SwiperComponent>
                </div>
            </div>
        </>
    );
};

export default LearnPage;